.oi-bilan-cards{
  display: flex;
  flex-flow: row wrap;
}

.oi-bilan-card{
  flex: 1 1 23%;
  max-width: 23%;
  margin: 0 2% 20px 0;
  padding: 20px;
  position: relative;

  @include rem(font-size, 30px);
  text-align: center;

  border-radius: $prop_border_radius;
  border: 1px solid $c_outline_inactive;

  transition: all ease .3s;

  &:hover{
    color: #fff;
    border-color: $c_outline;
    background-color: $c_main;
  }

  > .icon{
    @include rem(font-size, 60px);
  }

  > .body{
    > .title{
      margin: 20px 0;
    }
  }
}

.oi-trames-empty{
  margin: 40px auto;
  text-align: center;

  > .icon{
    opacity: .3;
    width: 50px;
  }
}