@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
  url("../../fonts/OpenSans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
}


@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
  url("../../fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}


@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
  url("../../fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
}