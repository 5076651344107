
.oi-editor-header{
  display: flex;
  align-items: center;
  padding: 10px;

  > .title{
    margin: 0;
    outline: none;
    transition: all ease .3s;

    &:focus{
      padding: 5px 10px;
      border: 1px solid $c_outline;
    }
  }
}

.oi-editor-title{

  transition: background-color ease .3s, padding ease .3s;

  &:focus{
    outline: none;
    padding: 5px;
    background-color: $c_l5_main;
  }
}

.oi-field-row{
  flex-flow: row wrap;

  &.-mb{
    margin-bottom: 40px;
  }

  .input-field{
    margin: 0;
  }
}

.oi-editor-aside{
  position: sticky!important;
  top: 10px;

  > .content{
    max-height: 100vh;
    overflow: auto;
  }
}

.jodit-react-container,.jodit-popup{

  p,h1,h2,h3,h4,h5,h6,table{
    margin: 0!important;
  }

  h1,h2,h3,h4,h5,h6{
    font-weight: bold;
  }

  h1{
    font-size: 1.5em!important;
  }
  h2{
    font-size: 1.4em!important;
  }
  h3{
    font-size: 1.3em!important;
  }
  h4{
    font-size: 1.2em!important;
  }
  h5{
    font-size: 1.1em!important;
  }
  ul, ol{
    padding-left: 20px;
    margin-bottom: 0;
    margin-top: 0;

    &, & > li{
      list-style: inherit;
    }
  }

  ol{
    list-style: decimal;
  }

  table{
    width: auto;
  }
}