.oi-modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 100;
  opacity: 0;
  visibility: hidden;

  transition: visibility ease .3s, opacity ease .3s;

  &.-open{
    opacity: 1;
    visibility: visible;
  }

  > .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(#000,.9);
  }

  .modal{
    display: block;
    top: 50%;
    transform: translateY(-50%);
  }
}