/*****************************************\
*    MEDIAS
\*****************************************/

@mixin mq($point) {
  $mq-tabletfull: "(max-width: 1400px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)";

  @if $point == tabletfull {
    @media #{$mq-tabletfull} { @content; }
  }
  @else{
    @media (max-width: #{$point}){ @content; }
  }
}


/*****************************************\
*    PX TO REM
\*****************************************/

@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@mixin rem($property, $values) {
  $px : ();
  $rem: ();

  @each $value in $values {

    @if $value == 0 or $value == auto {
      $px : append($px , $value);
      $rem: append($rem, $value);
    }

    @else {
      $unit: unit($value);
      $val: parseInt($value);

      @if $unit == "px" {
        $px : append($px,  $value);
        $rem: append($rem, ($val / 10 + rem));
      }

      @if $unit == "rem" {
        $px : append($px,  ($val * 10 + px));
        $rem: append($rem, $value);
      }
    }
  }

  @if $px == $rem {
    #{$property}: $px;
  } @else {
    #{$property}: $px;
    #{$property}: $rem;
  }
}

/*********************************\
    WRAPPER
\*********************************/

@mixin wrapper-block(){
  margin: 0 auto;
  max-width: $c_wrapper_width;
  padding: 0 $c_wrapper_inner_margin;
}


@keyframes anim-alert {
  to{
    opacity: .3;
  }
}

@mixin anim-alert(){
  animation: anim-alert ease .3s infinite alternate;
}