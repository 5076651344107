.input-field .prefix.active{
  color: $c_main;
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]) {
  border-color: $c_main;
  box-shadow: 0 1px 0 0 $c_main;
}

input[type=text]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label {
  color: $c_main;
}

.material-tooltip{
 font-size: .8rem;
}

.ql-editor{
  padding: 0 !important;
}
.ql-editor.ql-blank::before{
  left: 0!important;
}

.ql-container {
  font-size: 16px!important;

  h1,h2,h3,h4,h5{
    font-weight: bold;
    margin: 0;
  }/*


  h1{
    margin-bottom: 18px!important;
  }

  h2{
    margin-bottom: 16px!important;
  }

  h3{
    margin-bottom: 14px!important;
  }

  h4{
    margin-bottom: 12px!important;
  }

  h5{
    margin-bottom: 10px!important;
  }

  p{
    margin-bottom: 5px!important;
  }
  */
}

.ql-clipboard {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
}

.ql-picker.ql-size .ql-picker-label::before,
.ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}

.brand-logo{
  img{
    margin-left: 20px;
    width: 150px;
  }
}