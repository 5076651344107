
.g-row{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.g-label{
  min-width: 100px;
}

.g-field{
  padding: 10px;
  border: 1px solid $c_outline_inactive;
  border-radius: $prop_border_radius;
  outline: none;

  &:focus{
    border-color: $c_outline;
  }
}