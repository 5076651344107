.oi-alert {
  position: fixed;
  bottom: 10px;
  right: 10px;
  flex: 1 1 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px 10px 10px;
  font-size: 12px;
  margin-bottom: 5px;

  border-radius: $prop_border_radius;

  z-index: 10;


  > .message{
    color: #fff;
  }

  > .icon{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}